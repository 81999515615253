import makeStyles from '@mui/styles/makeStyles';
import {border} from "./commonStyles";
import XIIcon from "../images/xiIcon.svg"
import dotIcon from "../images/LoginPage/dotted-bg.svg"

const App_URL = process.env.REACT_APP_AppUrl;
const SignUpPageStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // ...theme.mixins.toolbar,
        minHeight:'10px'
    },
    createContent: {
      flexGrow: 1,
      width: "100%",
      backgroundImage: `url(${App_URL}${XIIcon}) , url(${App_URL}${dotIcon})`,
      backgroundPosition: `top 10% right, top left`,
      backgroundRepeat: `no-repeat, repeat`,
      // backgroundSize: "10%, 100%",
      // marginTop:'100px'
  },
    content: {
      flexGrow: 1,
      width: "100%",
      background: `url(${App_URL}${XIIcon}) top right no-repeat`,
      backgroundSize: "10%",
      marginTop:'100px'
  },
    afterLoginContent: {
      flexGrow: 1,
      background: `url(${App_URL}${XIIcon}) top right no-repeat`,
      backgroundSize: "10%",
      marginLeft: theme.spacing(7),
      [theme.breakpoints.up(600)]: {
        marginLeft: theme.spacing(11),
      },
      paddingLeft: theme.spacing(2),
      marginTop:'100px'

    },
    beforeLoginContent: {
        flexGrow: 1,
        width: "100%",
        background: `url(${App_URL}${XIIcon}) top right no-repeat`,
        backgroundSize: "10%",
        marginTop:'100px'
    },
    form: {
        // border: border
    },
    successMessage:{
        backgroundColor: "rgba(0,128,0,0.4)",
    },
    failureMessage:{
        backgroundColor: "rgba(255,102,92,0.4)",
    },

  emailContent: {
    position: "relative",
    boxShadow: "0px 0px 25px 0px #E7E7E7",
    borderRadius: "20px",
    textAlign: "center",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.light,
    fontWeight: 700,
    fontSize: "1.5rem",
    height: "70px",
    width: "70px",
    textTransform:'uppercase'
  },
  instructions:{
    overflow:'auto'

  },
  details: {
    height: "100%",
    overflowX: "hidden",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "8px",
    },
  },
  trackingselect:{
    // minHeight:'83px !important',
    border:border,
    backgroundColor:"#FBFBFB",
    textAlign:'center',
    boxShadow:'none',
    // width:'180px',
    paddingTop:'0.75rem',
    paddingBottom:'0.75rem',
    width:"85%",
    marginTop:'1rem',
    '&.selected': {
      borderColor: '#F58120',
      borderWidth: '3px',
      backgroundColor: "#FBFBFB",
    },
    "&:hover": {
      backgroundColor: "#FBFBFB",
  }
  },
  configurebox:{
    backgroundColor:"#FFFCFA",
    // height:'85px',
    color:theme.palette.secondary.light,
    border:'1px solid #FFF4EB',
    borderRadius:'5px',
    alignItems:'center'
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: '1px solid #FF8735',
    color: '#FF8735',
    fontSize: '1.125rem',
  },
}));
export default SignUpPageStyles;