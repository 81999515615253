import React, { useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Tooltip,
} from "@mui/material";
import {
  ArrowForward,
  DesktopWindowsOutlined,
  InfoOutlined,
  MailOutline,
  Share,
  StayCurrentPortraitOutlined,
  TabletMacOutlined,
} from "@mui/icons-material";
import NumericLabel from "react-pretty-numbers";
import OverviewPageStyles from "../../assets/jss/overviewPageStyles";
import * as _ from "lodash";
import Icons from "../icons";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  connectionsTrackingDataStatusURL,
  settingConnectionsURL,
} from "../sidebar";

const SwitchMetricsAndCompare = (props) => {
  const { handleCompare, compare, data, currency, businessProfile } = props;
  return (
    <React.Fragment>
      <Box py={0} px={1} pt={0}>
        {compare ? (
          <Box pt={0}>
            <Box py={2} pt={0}>
              <Grid container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography
                    style={{
                      color: "#0D171C",
                      fontSize: "1.875rem",
                      fontWeight: 700,
                    }}
                  >
                    Key Metrics Comparision
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <TableBlock
                data={data}
                currency={currency}
                businessProfile={businessProfile}
              />
            </Box>
          </Box>
        ) : (
          <Grid container style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={6}>
              <Typography
                style={{ color: "#0D171C", fontWeight: 700, fontSize: "1rem" }}
              >
                Key Metrics
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Button
                onClick={() => handleCompare(true)}
                style={{ color: "#3B82F6", textTransform: "none" }}
              >
                Switch to compare mode &nbsp;
                <ArrowForward style={{ color: "#3B82F6", fontSize: "1rem" }} />
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </React.Fragment>
  );
};

const channels = [
  {
    channel: "google_paid_search",
    label: "Google Paid Search",
    icon: Icons.gpaid,
  },
  {
    channel: "google_organic_search",
    label: "Google Organic Search",
    icon: Icons.gorg,
  },
  {
    channel: "email",
    label: "Email",
    icon: <MailOutline style={{ color: "#00A3FF", fontSize: "20px" }} />,
  },
  {
    channel: "bing_paid_search",
    label: "Bing Paid Search",
    icon: Icons.bing,
  },
  {
    channel: "referral",
    label: "Referral",
    icon: <Share style={{ color: "#00A3FF", fontSize: "20px" }} />,
  },
  {
    channel: "google_referral",
    label: "Google Referral",
    icon: <Share style={{ color: "#00A3FF", fontSize: "20px" }} />,
  },
  {
    channel: "duckduckgo_organic_search",
    label: "DuckDuckGo organic search",
    icon: <Share style={{ color: "#00A3FF", fontSize: "20px" }} />,
  },
  {
    channel: "bing_organic_search",
    label: "Bing Organic Search",
    icon: Icons.bing,
  },
  {
    channel: "yahoo__organic_search",
    label: "Yahoo Organic Search",
    icon: Icons.yahoo,
  },
  { channel: "reddit_social", label: "Reddit Social", icon: Icons.reddit },
  {
    channel: "ecosia_organic_search",
    label: "Ecosia Organic Search",
    icon: <Share style={{ color: "#00A3FF", fontSize: "20px" }} />,
  },
  {
    channel: "facebook_social",
    label: "Facebook Social",
    icon: Icons.metaIcon,
  },
  {
    channel: "yahoo__paid_search",
    label: "Yahoo Paid Search",
    icon: Icons.yahoo,
  },
  {
    channel: "youtube_social",
    label: "Youtube Social",
    icon: Icons.yahoo,
  },
  {
    channel: "bronto_email",
    label: "Bronto Email",
    icon: <MailOutline style={{ color: "#00A3FF", fontSize: "20px" }} />,
  },
  {
    channel: "aol_organic_search",
    label: "Aol Organic Search",
    icon: <Share style={{ color: "#00A3FF", fontSize: "20px" }} />,
  },
];

const TableBlock = (props) => {
  const { data, currency, businessProfile } = props;
  const styles = OverviewPageStyles();
  const theme = useTheme();
  const paramOptions1 = {
    commafy: true,
    justification: "L",
    wholenumber: "ceil",
    shortFormatMinValue: 10000,
  };
  const paramOptions2 = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  const paramOptions3 = {
    commafy: true,
    justification: "L",
    precision: 1,
  };
  const paramOptions4 = {
    commafy: true,
    justification: "L",
    wholenumber: "ceil",
  };
  const paramOptions5 = {
    wholenumber: "ceil",
    justification: "L",
  };
  const hasLXRTracking =
    !businessProfile.isLoading &&
    businessProfile.bu_id &&
    _.some(businessProfile.data_sources, ["data_source_type", "tracking"]);
  const dataSourceLength =
    !businessProfile.isLoading && businessProfile.bu_id
      ? businessProfile.data_sources.length
      : null;
  const trackingReady = hasLXRTracking
    ? !businessProfile.isLoading &&
      businessProfile.bu_id &&
      businessProfile.data_sources.some((val) => {
        if (val.data_source_type === "tracking") {
          const dateReady = moment(val.date_ready);
          const startOfMonth = moment().startOf("month");
          return val.date_ready
            ? dateReady >= startOfMonth
              ? false
              : true
            : false;
        }
        return null;
      })
    : null;

  const [showTrackingData, setShowTrackingData] = React.useState();
  const [showLabel, setShowLabel] = React.useState("");

  useEffect(() => {
    if (hasLXRTracking) {
      if (dataSourceLength === 1) {
        setShowTrackingData(true);
      } else if (dataSourceLength > 1) {
        if (trackingReady) {
          setShowTrackingData(true);
        } else {
          setShowLabel("datastatus");
        }
      } else {
        setShowLabel("connections");
      }
    } else {
      setShowLabel("connections");
    }
  }, [hasLXRTracking, dataSourceLength, trackingReady]);

  // Get the 'all' segment's customers
  const allSegment = data.find((item) => item.segment === "all");
  const allCustomers = allSegment ? allSegment.customers : 1;
  const allOrders = allSegment ? allSegment.orders : 1;
  const allRevenue = allSegment ? allSegment.revenue : 1;
  const deviceChannelNode = (item, data, param, visitValueDiff) => {
    let totalallDevice = 0;
    let total1stDevice = 0;

    // Calculate total device visits
    if (data.device_all_visit) {
      totalallDevice += data.device_all_visit.DESKTOP || 0;
      totalallDevice += data.device_all_visit.MOBILE || 0;
      totalallDevice += data.device_all_visit.TABLET || 0;
    }

    // Calculate total 1st device visits
    if (data.device_1st_visit) {
      total1stDevice += data.device_1st_visit.DESKTOP || 0;
      total1stDevice += data.device_1st_visit.MOBILE || 0;
      total1stDevice += data.device_1st_visit.TABLET || 0;
    }
    // Results array to store the computed values
    if (item) {
      if (param === "channel") {
        return (
          <Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              p={1}
            >
              {item[0] ? (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  
                  <Tooltip
                    title={
                      channels.find((channel) => channel.channel === item[0][0])
                        ?.label
                        ? channels.find(
                            (channel) => channel.channel === item[0][0]
                          )?.label
                        : item[0][0]
                    }
                    arrow
                  >
                    {channels.find((channel) => channel.channel === item[0][0])
                      ?.icon ? (
                      channels.find((channel) => channel.channel === item[0][0])
                        ?.icon
                    ) : (
                      Icons.generic
                    )}
                  </Tooltip>
                  &nbsp;{Math.round(item[0][1]) + "%"}
                </span>
              ) : null}
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              p={1}
            >
              {item[1] ? (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ paddingTop: "10px" }}>
                    <Tooltip
                      title={
                        channels.find(
                          (channel) => channel.channel === item[1][0]
                        )?.label
                          ? channels.find(
                              (channel) => channel.channel === item[1][0]
                            )?.label
                          : item[1][0]
                      }
                      arrow
                    >
                      {channels.find(
                        (channel) => channel.channel === item[1][0]
                      )?.icon ? (
                        channels.find(
                          (channel) => channel.channel === item[1][0]
                        )?.icon
                      ) : (
                        Icons.generic
                      )}
                    </Tooltip>
                  </span>
                  &nbsp;{Math.round(item[1][1]) + "%"}
                </span>
              ) : null}
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              p={1}
            >
              {item[2] ? (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={
                      channels.find((channel) => channel.channel === item[2][0])
                        ?.label
                        ? channels.find(
                            (channel) => channel.channel === item[2][0]
                          )?.label
                        : item[2][0]
                    }
                    arrow
                  >
                    {channels.find((channel) => channel.channel === item[2][0])
                      ?.icon ? (
                      channels.find((channel) => channel.channel === item[2][0])
                        ?.icon
                    ) : (
                      Icons.generic
                    )}
                  </Tooltip>
                  &nbsp;{Math.round(item[2][1]) + "%"}
                </span>
              ) : null}
            </Box>
          </Box>
        );
      } else {
        return (
          <Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              p={1}
            >
              <Tooltip title={"Desktop"} arrow>
                <DesktopWindowsOutlined
                  style={{ color: "#00A850", fontSize: "1rem" }}
                />
              </Tooltip>
              &nbsp;
              {item.DESKTOP > 0
                ? Math.round(
                    (item.DESKTOP * 100) /
                      (visitValueDiff === "device_1st_visit"
                        ? total1stDevice
                        : totalallDevice)
                  ) + "%"
                : null}
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              p={1}
            >
              <Tooltip title={"Mobile"} arrow>
                <StayCurrentPortraitOutlined
                  style={{ color: "#00A3FF", fontSize: "1rem" }}
                />
              </Tooltip>
              &nbsp;
              {item.MOBILE > 0
                ? Math.round(
                    (item.MOBILE * 100) /
                      (visitValueDiff === "device_1st_visit"
                        ? total1stDevice
                        : totalallDevice)
                  ) + "%"
                : null}
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              p={1}
            >
              <Tooltip title={"Tablet"} arrow>
                <TabletMacOutlined
                  style={{ color: "#FFD54C", fontSize: "1rem" }}
                />
              </Tooltip>
              &nbsp;
              {item.TABLET > 0
                ? Math.round(
                    (item.TABLET * 100) /
                      (visitValueDiff === "device_1st_visit"
                        ? total1stDevice
                        : totalallDevice)
                  ) + "%"
                : null}
            </Box>
          </Box>
        );
      }
    } else {
      return "N/A";
    }
  };
  const sort_tp = (data, filter = true) => {
    let sorted_data = [];
    for (let channel in data) {
      sorted_data.push([channel, data[channel]]);
    }
    sorted_data.sort(function (a, b) {
      return b[1] - a[1];
    });
    if (filter) {
      sorted_data = _.filter(
        sorted_data,
        (o) => o[0] !== "direct" && o[0] !== "others"
      );
    }
    return sorted_data;
  };

  const customerPercentage = (segmentCustomers) => {
    return <>&nbsp;({Math.round((segmentCustomers * 100) / allCustomers)}%)</>;
  };
  const ordersPercentage = (segmentOrders) => {
    return <>&nbsp;({Math.round((segmentOrders * 100) / allOrders)}%)</>;
  };
  const revenuePercent = (rev) => {
    return <>({Math.round((rev * 100) / allRevenue)}%)</>;
  };
  const totalCustomersFormat = (cus) => {
    return <NumericLabel params={paramOptions1}>{cus}</NumericLabel>;
  };
  const revenueFormat = (reve) => {
    return <NumericLabel params={paramOptions2}>{reve}</NumericLabel>;
  };
  const perCustomerFormat = (ord) => {
    return <NumericLabel params={paramOptions3}>{ord}</NumericLabel>;
  };
  const avgDaysFormat = (day) => {
    return (
      <>
        <NumericLabel params={paramOptions4}>{day}</NumericLabel> day(s)
      </>
    );
  };
  const secondOrderCondition = (seg) => {
    return (
      seg.days_gap_1 !== 0 &&
      seg.days_gap_1 !== "" &&
      seg.orders / seg.customers > 1.05
    );
  };

  const [tableSections, setTableSections] = React.useState([]);
  const columnHeaders = [
    "High Value Customers",
    "Mid Value Customers",
    "Low Value Customers",
    "All Customers",
  ];
  const columnColors = [
    theme.palette.segment.high,
    theme.palette.segment.mid,
    theme.palette.segment.low,
    theme.palette.primary.main,
  ];
  const columnbgColors = ["#FFEAD9", "#E1F4FF", "#F1F1F1", "#F4F9FF"];
  useEffect(() => {
    const tableSection = [
      {
        title: "Overall Performance",
        label: "overall",
        rows: [
          {
            label: "Total Customers",
            values: data.map((item) => (
              <>
                {totalCustomersFormat(item.customers)}
                {item.segment !== "all"
                  ? customerPercentage(item.customers)
                  : null}
              </>
            )),
          },
          {
            label: "Total Orders",
            values: data.map((item) => (
              <>
                {totalCustomersFormat(item.orders)}
                {item.segment !== "all" ? ordersPercentage(item.orders) : null}
              </>
            )),
          },
          {
            label: "Total Revenue",
            values: data.map((item) => (
              <>
                {revenueFormat(item.revenue)}{" "}
                {item.segment !== "all" ? revenuePercent(item.revenue) : null}
              </>
            )),
          },
        ],
      },
      {
        title: "Customer Engagement",
        label: "customer_engagement",
        rows: [
          {
            label: "Avg. Recency",
            values: data.map((item) =>
              item.avg_recency ? avgDaysFormat(item.avg_recency) : "N/A"
            ),
          },
          {
            label: "Avg. Monthly Visits",
            values: data.map((item) => (
              <>
                {item.avg_monthly_visits ? (
                  <NumericLabel params={paramOptions5}>
                    {item.avg_monthly_visits}
                  </NumericLabel>
                ) : (
                  "N/A"
                )}
              </>
            )),
          },
          {
            label: "Avg. No. of Touchpoints (1st Conversion)",
            values: data.map((item) => (
              <>
                {item.first_path_avg_purchase_tps ? (
                  <NumericLabel params={paramOptions5}>
                    {item.first_path_avg_purchase_tps}
                  </NumericLabel>
                ) : (
                  "N/A"
                )}
              </>
            )),
          },
        ],
      },
      {
        title: "Purchase Behavior",
        label: "purchase_behavior",
        rows: [
          {
            label: "Avg. Order per Customer",
            values: data.map((item) =>
              perCustomerFormat(item.orders / item.customers)
            ),
          },
          {
            label: "Avg. Days for 1st Order",
            values: data.map((item) =>
              item.latency ? avgDaysFormat(item.latency / 24) : null
            ),
          },
          {
            label: "Avg. Days for 2nd Order",
            values: data.map((item) =>
              secondOrderCondition(item)
                ? avgDaysFormat(item.days_gap_1)
                : "N/A"
            ),
          },
          {
            label: "Avg. Gap between 1st Visit and 1st Conversion",
            values: data.map((item) =>
              item.latency ? (
                <>
                  <NumericLabel params={paramOptions5}>
                    {item.latency / 24}
                  </NumericLabel>
                  &nbsp;day(s)
                </>
              ) : (
                "N/A"
              )
            ),
          },
          {
            label: "Avg. Gap between 1st and 2nd Conversion",
            values: data.map((item) =>
              item.days_gap_1 !== 0 &&
              item.days_gap_1 !== "" &&
              item.orders / item.customers > 1.05 ? (
                <>
                  <NumericLabel params={paramOptions5}>
                    {item.days_gap_1}
                  </NumericLabel>
                  &nbsp;day(s)
                </>
              ) : (
                "N/A"
              )
            ),
          },
          {
            label: "Avg. Gap between Conversions",
            values: data.map((item) =>
              item.avg_purchase_gap ? (
                <>
                  <NumericLabel params={paramOptions5}>
                    {item.avg_purchase_gap}
                  </NumericLabel>
                  &nbsp;day(s)
                </>
              ) : (
                "NA"
              )
            ),
          },
        ],
      },
      {
        title: "Revenue and Value",
        label: "revenue_value",
        rows: [
          {
            label: "Avg. Revenue per Customer",
            values: data.map((item) =>
              revenueFormat(item.revenue / item.customers)
            ),
          },
          {
            label: "Avg. Order Value",
            values: data.map((item) =>
              revenueFormat(item.revenue / item.orders)
            ),
          },
          {
            label: "Avg. 1st Order Value",
            values: data.map((item) =>
              item.aov_1 ? revenueFormat(item.aov_1) : "N/A"
            ),
          },
          {
            label: "Avg. 2nd Order Value",
            values: data.map((item) =>
              item.days_gap_1 !== 0 &&
              item.days_gap_1 !== "" &&
              item.orders / item.customers > 1.05
                ? revenueFormat(item.aov_2)
                : "N/A"
            ),
          },
        ],
      },
      {
        title: "Product Metrics",
        label: "product_metrics",
        rows: [
          {
            label: "Avg. Number of Items in an Order",
            values: data.map((item) =>
              item.items ? (
                <NumericLabel param={paramOptions4}>
                  {item.items / item.orders}
                </NumericLabel>
              ) : (
                "N/A"
              )
            ),
          },
          {
            label: "Top Product (by Revenue)",
            values: data.map((item) =>
              item.top_product ? item.top_product : "N/A"
            ),
          },
        ],
      },
      {
        title: "Conversion Path Metrics",
        label: "conv_path",
        rows: [
          {
            label: "1st Visit to Conversion Path",
            values: data.map((item) =>
              deviceChannelNode(
                item.device_1st_visit,
                item,
                "device",
                "device_1st_visit"
              )
            ),
          },
          {
            label: "Visits to Conversion Path",
            values: data.map((item) =>
              deviceChannelNode(
                item.device_all_visit,
                item,
                "device",
                "device_all_visit"
              )
            ),
          },
          {
            label: "1st Touchpoint Share for 1st Conversion Path",
            values: data.map((item) =>
              deviceChannelNode(
                sort_tp(item.first_path_first_tp_share),
                item,
                "channel"
              )
            ),
          },
          {
            label: "Touchpoint Share for 1st Conversion Path",
            values: data.map((item) =>
              deviceChannelNode(
                sort_tp(item.first_path_tp_share),
                item,
                "channel"
              )
            ),
          },
          {
            label: "Last Touchpoint Share for 1st Conversion Path",
            values: data.map((item) =>
              deviceChannelNode(
                sort_tp(item.first_path_last_tp_share),
                item,
                "channel"
              )
            ),
          },
        ],
      },
    ];

    setTableSections(tableSection);
  }, [data]);

  const labels = [
    "Avg. Gap between 1st Visit and 1st Conversion",
    "Avg. Gap between Conversions",
    "Avg. Gap between 1st and 2nd Conversion",
    "Avg. No. of Touchpoints (1st Conversion)",
    "Avg. Monthly Visits",
  ];

  const buttons = [
    {
      value: "overall",
      title: "Overall Performance",
      color: "#ff9800",
    },
    {
      value: "customer_engagement",
      title: "Customer Engagement",
      color: "#f57c00",
    },
    {
      value: "purchase_behavior",
      title: "Purchase Behavior",
      color: "#03a9f4",
    },
    {
      value: "revenue_value",
      title: "Revenue and Value",
      color: "#0288d1",
    },
    {
      value: "product_metrics",
      title: "Product Metrics",
      color: "#8bc34a",
    },
    {
      value: "conv_path",
      title: "Conversion Path Metrics",
      color: "#009688",
    },
  ];
  const [selected, setSelected] = React.useState("overall");

  const handleTableSegmentClick = (val) => {
    setSelected(val);
  };

  const sectionRefs = useRef({});

  const scrollToRow = (sectionLabel) => {
    const section = sectionRefs.current[sectionLabel];
    if (section) {
      const tableContainer = section.closest(".MuiTableContainer-root");
      if (tableContainer) {
        // Get the position of the section and scroll it into view
        const sectionOffsetTop = section.offsetTop;
        tableContainer.scrollTo({
          top: sectionOffsetTop - 50,
          behavior: "smooth",
        });
      }
    }
  };

  // Debounced function to control handleTableSegmentClick calls when scrolling stops
  const debouncedHandleTableSegmentClick = _.debounce((label) => {
    handleTableSegmentClick(label);
    setSelected(label); // Update the selected button value
  }, 200); // Delay of 200 ms

  // Function to update the selected button based on the visible row
  const updateSelectedButtonOnScroll = () => {
    const tableContainer = document.querySelector(".MuiTableContainer-root");
    if (tableContainer) {
      const scrollPosition = tableContainer.scrollTop;
      let closestSectionLabel = "";

      for (const [label, section] of Object.entries(sectionRefs.current)) {
        if (section.offsetTop - 70 <= scrollPosition) {
          closestSectionLabel = label; // Update closest section label as we scroll
        }
      }
      if (closestSectionLabel) {
        debouncedHandleTableSegmentClick(closestSectionLabel); // Update selected button value
      }
    }
  };

  useEffect(() => {
    const tableContainer = document.querySelector(".MuiTableContainer-root");

    if (tableContainer) {
      tableContainer.addEventListener("scroll", updateSelectedButtonOnScroll);
    }

    // Cleanup scroll listener
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener(
          "scroll",
          updateSelectedButtonOnScroll
        );
      }
    };
  }, []);

  return (
    <React.Fragment>
      <Box
        p={2}
        pt={0}
        style={{
          position: "sticky",
          zIndex: 9,
        }}
      >
        <Typography
          style={{ fontSize: "0.75rem", color: "#1E334E", paddingLeft: "6px" }}
          component={"span"}
        >
          Go to Section :
        </Typography>
        {buttons.map((button) => (
          <Button
            key={button.value}
            style={{
              textTransform: "none",
              backgroundColor:
                selected === button.value ? "#FFF4EB73" : "#F4F4F4",
              border: selected === button.value ? "1px solid #F58120" : null,
              borderRadius: "15px",
              color: selected === button.value ? "#F58120" : "#747474",
              padding: "5px 20px",
              margin: "4px 4px",
              fontSize: "0.75rem",
            }}
            color="primary"
            onClick={() => {
              handleTableSegmentClick(button.value);
              scrollToRow(button.value);
            }}
          >
            {button.title}
          </Button>
        ))}
      </Box>
      <Box
        style={{
          backgroundColor: theme.palette.common.white,
          borderRadius: "15px",
        }}
        p={2}
      >
        <TableContainer
          style={{
            minWidth: "96%",
            height: "auto",
            maxHeight: window.innerHeight - 70 - 250,
            overflow: "auto",
            position: "absoulte",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {columnHeaders.map((header, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    style={{
                      backgroundColor: columnColors[index],
                      fontWeight: "bold",
                      color: theme.palette.common.white,
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableSections.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  <TableRow
                    ref={(el) => (sectionRefs.current[section.label] = el)}
                  >
                    <TableCell
                      colSpan={5}
                      style={{
                        backgroundColor: "#E7EEF7",
                        fontWeight: 600,
                        padding: "10px 16px",
                        fontSize: "0.75rem",
                      }}
                    >
                      {section.title}
                    </TableCell>
                  </TableRow>
                  {section.label === "conv_path" ? (
                    <React.Fragment>
                      {showTrackingData ? (
                        section.rows.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                backgroundColor: theme.palette.common.white,
                                color: "#838383",
                                fontSize: "0.75rem",
                              }}
                            >
                              {row.label}
                            </TableCell>
                            {labels.includes(row.label) && !showTrackingData ? (
                              <TableCell
                                colSpan={4}
                                style={{ backgroundColor: "#FCFCFC" }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#838383",
                                  }}
                                >
                                  <InfoOutlined />
                                  &nbsp; No Data Available
                                </Typography>
                              </TableCell>
                            ) : (
                              row.values.map((value, colIndex) => (
                                <TableCell
                                  key={colIndex}
                                  align="center"
                                  style={{
                                    backgroundColor: columnbgColors[colIndex],
                                    fontWeight: 600,
                                  }}
                                >
                                  {value}
                                </TableCell>
                              ))
                            )}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell style={{ padding: 0 }}>
                            <TableRow>
                              <TableCell
                                style={{
                                  backgroundColor: theme.palette.common.white,
                                  color: "#838383",
                                  fontSize: "0.75rem",
                                }}
                              >
                                1st Visit to Conversion Path
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{
                                  backgroundColor: theme.palette.common.white,
                                  color: "#838383",
                                  fontSize: "0.75rem",
                                }}
                              >
                                Visits to Conversion Path
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{
                                  backgroundColor: theme.palette.common.white,
                                  color: "#838383",
                                  fontSize: "0.75rem",
                                }}
                              >
                                1st Touchpoint Share for 1st Conversion Path
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{
                                  backgroundColor: theme.palette.common.white,
                                  color: "#838383",
                                  fontSize: "0.75rem",
                                }}
                              >
                                Touchpoint Share for 1st Conversion Path
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{
                                  backgroundColor: theme.palette.common.white,
                                  color: "#838383",
                                  fontSize: "0.75rem",
                                }}
                              >
                                Last Touchpoint Share for 1st Conversion Path
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            colSpan={5}
                            align="center"
                            style={{
                              backgroundColor: theme.palette.common.white,
                              color: "#838383",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                              padding: "16px",
                            }}
                          >
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <InfoOutlined />
                                &nbsp; ​No Data Available
                              </Typography>
                              {Icons.noData}
                              {showLabel === "datastatus" ? (
                                <Box
                                  p={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link
                                    to={connectionsTrackingDataStatusURL}
                                    style={{
                                      color: theme.palette.secondary.light,
                                      textdecoration: "underline",
                                    }}
                                  >
                                    View Data Status
                                  </Link>
                                  <Typography
                                    style={{
                                      color: "#838383",
                                      fontSize: "14px",
                                    }}
                                  >
                                    &nbsp;&nbsp;for more details
                                  </Typography>
                                </Box>
                              ) : (
                                <Box
                                  p={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link
                                    to={settingConnectionsURL}
                                    style={{
                                      color: theme.palette.secondary.light,
                                      textdecoration: "underline",
                                    }}
                                  >
                                    Connect to LXRTracking Script
                                  </Link>
                                  <Typography
                                    style={{
                                      color: "#838383",
                                      fontSize: "14px",
                                    }}
                                  >
                                    &nbsp;&nbsp;to see more data
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {section.rows.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              backgroundColor: theme.palette.common.white,
                              color: "#838383",
                              fontSize: "0.75rem",
                            }}
                          >
                            {row.label}
                          </TableCell>
                          {labels.includes(row.label) && !showTrackingData ? (
                            <TableCell
                              colSpan={4}
                              style={{ backgroundColor: "#FCFCFC" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#838383",
                                }}
                              >
                                <InfoOutlined />
                                &nbsp; No Data Available
                              </Typography>
                            </TableCell>
                          ) : (
                            row.values.map((value, colIndex) => (
                              <TableCell
                                key={colIndex}
                                align="center"
                                style={{
                                  backgroundColor: columnbgColors[colIndex],
                                  fontWeight: 600,
                                }}
                              >
                                {value}
                              </TableCell>
                            ))
                          )}
                        </TableRow>
                      ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default SwitchMetricsAndCompare;
