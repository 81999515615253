import makeStyles from "@mui/styles/makeStyles";
import { border, cardShadow } from "./commonStyles";

const OverviewPageStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    marginLeft: theme.spacing(7),
    [theme.breakpoints.up(600)]: {
      marginLeft: theme.spacing(11),
    },
    paddingLeft: theme.spacing(2),
    backgroundColor: "#F7FAFA",
  },
  content1: {
    flexGrow: 1,
    marginLeft: theme.spacing(7),
    [theme.breakpoints.up(600)]: {
      marginLeft: theme.spacing(11),
    },
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  headerStyles: {
    backgroundColor: "#F7FAFA",
    paddingTop: 16,
    paddingBottom: 8,
    paddingLeft: 16,
    border: border,
  },
  cardClass: {
    boxShadow: cardShadow,
    border: border,
    borderRadius: 6,
    minHeight: "100%",
  },
  rightArrow: {
    border: "solid #E3E6F4",
    borderWidth: "0 1px 1px 0",
    display: "inline-block",
    paddingTop: 30,
    paddingBottom: 15,
    paddingRight: 30,
    paddingLeft: 15,
    transform: "rotate(-45deg)",
    webkitTransform: "rotate(-45deg)",
  },
  arrowRight: {
    width: 0,
    height: 0,
    borderTop: "32px solid transparent",
    borderBottom: "32px solid transparent",
    borderLeft: "20px solid #E3E6F4",
  },
  arrowRightDiv: {
    width: 0,
    position: "relative",
    top: -31,
    left: -20,
    borderTop: "31px solid transparent",
    borderBottom: "31px solid transparent",
    borderLeft: "19px solid white",
  },
  magicWand: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  scrollbar: {
    overflowX: "hidden",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "8px",
    },
  },
  header: {
    // position: "sticky",
    // top: 60,
    // zIndex: 10,
    // backgroundColor: "#F7FAFA",
  },
}));

export default OverviewPageStyles;
