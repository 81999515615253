import React, {useEffect} from "react"
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {makeStyles, withStyles} from '@mui/styles';
import SignUpPageStyles from "../../assets/jss/signupPageStyles";
import {OrangeFilledButton, TransparentButton} from "../buttons";
import {connect} from "react-redux";
import {clearSignupStatus, handle_sign_up} from "../../actions/actions";
import {Link} from "react-router-dom";
import Icons from "../icons";
import {HtmlTooltip} from "../SettingsPage/changePassword";
import {border} from '../../assets/jss/commonStyles';
import {PersonAddAlt, Replay} from "@mui/icons-material";


const CustomCheckbox = withStyles(theme => ({
    root: {
        color:theme.palette.primary.main,
        '&$checked': {
            // color: "#f58120 !important"
            // color: theme.palette.secondary.light
        },
    },
    checked: {}
}))(Checkbox);

const StyledSelect = withStyles(theme => ({
    outlined: {
        padding: '10px',
    },
}))(Select);

export function CustomTextField({error,maxLength,...props}) {
    const theme = useTheme();
    return (
        <TextField
            variant={"outlined"}
            margin="none"
            required
            inputProps={{style: {padding: 10}, maxLength: maxLength || 50}}
            InputProps={{style: {fontSize: "0.875rem", color: theme.palette.primary.main,borderRadius:0,borderColor:error?theme.palette.error:theme.palette.primary.main}}}
            InputLabelProps={{
                style: {
                    fontSize: "0.875rem",
                    color: theme.palette.primary.main
                }
            }}
            sx={{
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor:"#a4a4a4"
                }
              }}
            error = {error}
            fullWidth
            autoComplete={'off'}
            FormHelperTextProps={{
                style: {
                    color:theme.palette.primary.contrastText,
                    margin: 0,
                    fontSize: '0.875rem'
                }
            }} {...props}/>
    )
}
const selectStyles = makeStyles((theme) => ({
    menuPaper:{
        maxHeight: 200,
    },
    select:{
        color: theme.palette.primary.main,
        "&$disabled": {
            color: theme.palette.primary.main,
            WebkitTextFillColor: theme.palette.primary.main,
        },
    }
}));

export function CustomSelect(props) {
    const theme = useTheme();
    const classes = selectStyles();
    return (
        <StyledSelect
            variant={"outlined"}
            margin="none"
            required
            inputProps={{style: {padding: 10}, maxLength: 150,}}
            InputProps={{style: {fontSize: "1rem", color: theme.palette.primary.main,}}}
            InputLabelProps={{
                style: {
                    fontSize: "0.875rem",
                    color: theme.palette.primary.main,
                }
            }}
            classes={{
                select: classes.select, // Apply custom select class
                disabled: {} // Apply custom disabled class if needed
            }}
            sx={{
                "&:hover": {
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#a4a4a4"
                  }
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: 0,
                },
                // ...(props.disabled && { // Apply styles if disabled
                //     WebkitTextFillColor:theme.palette.primary.main, 
                //     "-webkit-text-fill-color":theme.palette.primary.main, 
                // })
              }}
            MenuProps={{classes:{paper:classes.menuPaper},anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                },
                getcontentanchorel: null,
            }}
            fullWidth
            autoComplete={'off'}
            FormHelperTextProps={{
                style: {
                    color: theme.palette.primary.contrastText,
                    margin: 0,
                    fontSize: '0.875rem',
                }
            }} {...props}/>
    )
}

// const ResponseMessage=(props)=>{
//     const classes = SignUpPageStyles();
//     const {signUpReducer,response}=props;
//     return (
//       <React.Fragment>
//         {signUpReducer.status ? (
//           signUpReducer.status === "success" ? (
//             <Box
//               className={classes.successMessage}
//               style={{ textAlign: "center" }}
//             >
//               <Box py={2}>
//                 <Typography color={"primary"} style={{ fontWeight: 700 }}>
//                   Account Created Successfully
//                 </Typography>
//               </Box>
//             </Box>
//           ) : (
//             <Box
//               className={classes.failureMessage}
//               style={{ textAlign: "center" }}
//             >
//               <Box py={2}>
//                 <Typography color={"primary"} style={{ fontWeight: 700 }}>
//                   Failed, {response.message}
//                 </Typography>
//               </Box>
//             </Box>
//           )
//         ) : null}
//       </React.Fragment>
//     );
// };

const ResponseMessage = (props) => {
    const classes = SignUpPageStyles();
    const { signUpReducer, response } = props;
    const [showMessage, setShowMessage] = React.useState(false);
  
    useEffect(() => {
      if (signUpReducer.status) {
        setShowMessage(true);
  
        // const timer = setTimeout(() => {
        //   setShowMessage(false);
        // }, 10000);
  
        // return () => {
        //   clearTimeout(timer);
        // };
      }
    }, [signUpReducer.status]);
  
    return (
      <React.Fragment>
        {showMessage && (
          <Box
            className={
              signUpReducer.status === "success"
                ? classes.successMessage
                : classes.failureMessage
            }
            style={{ textAlign: "center" }}
          >
            <Box py={2}>
              <Typography color={"primary"} style={{ fontWeight: 700 }}>
                {signUpReducer.status === "success"
                  ? "Account Created Successfully"
                  : `Failed, ${response.message}`}
              </Typography>
            </Box>
          </Box>
         )}
      </React.Fragment>
    );
  };
  

function CreateAccount(props) {
    const {signUpReducer, handle_sign_up,clearSignupStatus} = props;
    const classes = SignUpPageStyles();
    const [first_name, setFirstName] = React.useState('');
    const [last_name, setLastName] = React.useState('');
    const [company_name, setCompanyName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirm_password, setConfirmPassword] = React.useState('');
    const [terms_accepted, set_terms_accepted] = React.useState(false);
    const [firstNameError, setFirstNameError] = React.useState('');
    const [lastNameError, setLastNameError] = React.useState('');
    const [companyNameError, setCompanyNameError] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');
    const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
    const [reset, setReset] = React.useState(false);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        };

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`, function () {
            return null
        });
    }, []);
    // const sampleNameValidator=(input)=>{
    //     let flag = true;
    //     const charValid=(char)=>{
    //         return((char >64 && char<91)||(char >96 && char <123))

    //     };
    //     if(input.length < 1){
    //         flag=false;
    //     }

    //     for(let i=0;i<=input.length-1;i++){
    //         //no space at first and last charecter
    //         if(input.charCodeAt(0)===32 || input.charCodeAt(input.length-1)===32){
    //             flag=false;
    //         }
    //         //only charecters and spaces are allowed
    //         if(!charValid(input.charCodeAt(i)) && input.charCodeAt(i)!==32){
    //             flag =false;
    //         }
    //     }

    //     return flag;

    // };
    const nameValidator=(input)=>{
        // let flag = true;
        let flag;
        let reg1=/^[A-Za-z0-9]{1}[A-Za-z0-9 \-']*[A-Za-z0-9]{1}$/;
        let reg2=/^[0-9]+$/;
        if(input.match(reg1)){
        if(!input.match(reg2)) {
            flag =true;
        }else{
            flag = false
        }
        }else{
            flag=false
        }
        return flag;
    };
    const companyNameValidator=(input)=>{
        // let flag = true;
        let flag;
        // let reg1=/^[A-Za-z0-9]{1}[\s\S]*[A-Za-z0-9.]{1}$/;
        let reg1=/^[A-Za-z0-9]{1}[\s\S]*[\S]{1}$/;
        let reg2=/^[0-9]+$/;
        if(input.match(reg1)){
        if(!input.match(reg2)) {
            flag =true;
        }else{
            flag = false
        }
        }else{
            flag=false
        }
        return flag;
    };
    const checkNames = (input, fieldError,field) => {
        if (!nameValidator(input)) {
          fieldError("Enter valid " + field + " Name");
        } else {
          fieldError("");
        }
        return null
    };
    const checkCompanyNames = (input, fieldError,field) => {
        if (!companyNameValidator(input)) {
          fieldError("Enter valid " + field + " Name");
        } else {
          fieldError("");
        }
        return null
    };
    useEffect(()=>{
        if(localStorage.getItem('access')){
            window.location.hash = '/'
        }
    },[signUpReducer]);
    //eslint-disable-next-line
    const genEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    const verifyEmail = (emailGiven) => {
        const trimmedEmail = emailGiven.trim();
        if (trimmedEmail.match(genEmail)) {
            setEmailError("");
            return true
        }else {
            setEmailError("Enter valid Email");
            return false
        }
    };
  //eslint-disable-next-line
    const passw = /^(?=.*[\d])[\w!@#$%^&*]{8,15}$/;
    const checkPassword = (inputtxt) => {
        if (!!inputtxt.match(passw)) {
            setPasswordError("");
            return true
        } else {
            setPasswordError("Enter valid Password");
            return false
        }
    };
    const handleSubmit = (e, data) => {
        e.preventDefault();
        clearSignupStatus();
        data["first_name"].trim();
        data["last_name"].trim(); 
        data["company_name"].trim();
        if(process.env.REACT_APP_RUN_RECAPTCHA) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(token => {
                    data['token'] = token;
                    handle_sign_up(e, data);
                    // props.handle_login(e, {email: username, password, token})
                });
            });
        }else{
            handle_sign_up(e, data);
        }
        setReset(false)
        // handle_sign_up(e, data);
        // setFirstName("")
        // setLastName("")
        // setCompanyName("")
        // setEmail("")
        // setPassword("")
        // setConfirmPassword("")
        // set_terms_accepted("")
    };

    const handleReset=()=>{
        setFirstName("")
        setLastName("")
        setCompanyName("")
        setEmail("")
        setPassword("")
        setConfirmPassword("")
        set_terms_accepted("")
        clearSignupStatus()
        setReset(true)
    }

    const validateForm = () => {
        return (
            (nameValidator(first_name)) &&
            (nameValidator(last_name)) &&
            (companyNameValidator(company_name)) &&
            (email.length > 0) &&
            (password.length > 0) &&
            (confirm_password.length > 0) &&
            (terms_accepted) &&
            (!!password.match(passw)) &&
            (!!confirm_password.match(passw)) &&
            (password === confirm_password) &&
            (email.match(genEmail))
        )
    };
    const theme = useTheme()
    return (
        <div>
            {/* <Grid container>
                <Grid item xs={4}/>
                <Grid item xs={4}>
                    <Box p={2} >
                        <Container maxWidth="md" component="main">
                            <Box py={2}>
                                <ResponseMessage signUpReducer={signUpReducer} response={signUpReducer.data}/>
                            </Box>
                            <Box py={2} pb={3} >
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={12}>
                                        <Typography variant={'h1'} color={'primary'}
                                                    style={{fontWeight: 700}}>
                                           Create Account
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <form id="signupForm" className={classes.form} autoComplete={'off'} onSubmit={e =>
                                handleSubmit(e, {
                                    first_name,
                                    last_name,
                                    email,
                                    password,
                                    confirm_password,
                                    company_name,
                                    terms_accepted
                                })
                            }>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Typography component={'span'} color={'primary'} >First
                                            Name*</Typography>
                                        <Box mb={2} mt={1}>
                                            <CustomTextField
                                                helperText={firstNameError}
                                                onFocus={() => setFirstNameError('')}
                                                onBlur={() => {
                                                    checkNames(first_name, setFirstNameError,"First")
                                                }}
                                                placeholder={'Type your First Name'}
                                                type="text"
                                                name="first_name"
                                                value={first_name}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                    checkNames(e.target.value, setFirstNameError,"First")
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                <Grid item xs={12} md={6} >
                                <Typography component={'span'} color={'primary'}>Last
                                    Name*</Typography>
                                <Box mb={2} mt={1}>
                                    <CustomTextField
                                        helperText={lastNameError}
                                        onFocus={() => setLastNameError('')}
                                        onBlur={() => {
                                            checkNames(last_name, setLastNameError,"Last")
                                        }}
                                        placeholder={'Type your Last Name'}
                                        type="text"
                                        name="last_name"
                                        value={last_name}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            checkNames(e.target.value, setLastNameError,"Last")
                                        }}
                                    />
                                </Box>
                                </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid xs={12} md={12}>
                                    <Typography component={'span'} color={'primary'} >Company
                                        Name*</Typography>
                                    <Box mb={2} mt={1}>
                                        <CustomTextField
                                            helperText={companyNameError}
                                            onFocus={() => setCompanyNameError('')}
                                            onBlur={() => {
                                                checkCompanyNames(company_name, setCompanyNameError,"Company")
                                            }}
                                            placeholder={'Type your Company Name'}
                                            type="text"
                                            name="company_name"
                                            value={company_name}
                                            onChange={(e) => {
                                                setCompanyName(e.target.value);
                                                checkCompanyNames(e.target.value, setCompanyNameError,"Company")
                                            }}
                                        />
                                    </Box>
                                    </Grid>
                                </Grid>
                                <Typography component={'span'} color={'primary'}>Email
                                    Address*</Typography>
                                <Box mb={2} mt={1}>
                                    <CustomTextField
                                        helperText={emailError}
                                        onFocus={() => setEmailError('')}
                                        onBlur={() => {
                                            verifyEmail(email)
                                        }}
                                        placeholder={'Type your Email'}
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            verifyEmail(e.target.value)
                                        }}
                                    />
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                <Typography component={'span'} color={'primary'}>Create a
                                    Password*</Typography>&ensp;<HtmlTooltip
                                title={<Typography variant={"body2"} color={'primary'}>
                                    <ul style={{padding: 8, margin: 8, marginTop: 0, marginBottom: 0}}>
                                        <li>Your password can't be too similar to your other personal information.</li>
                                        <li>Your password must contain at least 8 characters.</li>
                                        <li>Your password can't be a commonly used password.</li>
                                        <li>Your password can't be entirely numeric.</li>
                                    </ul>
                                </Typography>} placement={"right"}
                                interactive="true">{Icons.infoCircleIconOutlineMargin}</HtmlTooltip>
                                <Box mb={2} mt={1}>
                                    <CustomTextField
                                        helperText={passwordError}
                                        onFocus={() => setPasswordError('')}
                                        onBlur={() => {
                                            checkPassword(password)
                                        }}
                                        placeholder={'Type a Strong Password'}
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            checkPassword(e.target.value)
                                        }}
                                    />
                                </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <Typography component={'span'} color={'primary'}>Confirm
                                    Password*</Typography>
                                <Box mb={2} mt={1}>
                                    <CustomTextField
                                        helperText={confirmPasswordError}
                                        onFocus={() => setConfirmPasswordError('')}
                                        onBlur={() => {
                                            if (password !== confirm_password) {
                                                setConfirmPasswordError("Password doesn't match")
                                            } else {
                                                setConfirmPasswordError("")
                                            }
                                        }}
                                        placeholder={'Confirm Password'}
                                        type="password"
                                        name="confirm_password"
                                        value={confirm_password}
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                            if (password !== e.target.value) {
                                                setConfirmPasswordError("Password doesn't match")
                                            } else {
                                                setConfirmPasswordError("")
                                            }
                                        }}
                                    />
                                </Box>
                                </Grid>
                                </Grid>
                                <Box mb={2}>
                                    <Grid container alignItems={"baseline"}>
                                        <Grid item xs={1}>
                                            <FormControlLabel
                                                control={<CustomCheckbox checked={terms_accepted}
                                                                         onChange={(e, newChecked) => {
                                                                             set_terms_accepted(newChecked)
                                                                         }} name="terms_accepted"/>}
                                                component={'span'} style={{fontSize: 20, marginRight: 16}}
                                            />
                                        </Grid>
                                        <Grid item xs={11}><Typography color={'primary'} component={'span'}>I agree to
                                            LXRInsights'&nbsp;
                                            <Link target="_blank" rel="noopener noreferrer" to={'/terms'}
                                                  onClick={() => window.scrollTo(0, 0)}>T&C</Link> and<Typography component={'span'}> </Typography>
                                            <Link target="_blank" rel="noopener noreferrer" to={'/privacy-policy'}
                                                  onClick={() => window.scrollTo(0, 0)}>Privacy
                                                Policy</Link>.</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <div>
                                    <OrangeFilledButton
                                        type="submit"
                                        disabled={(!validateForm())||(signUpReducer.isLoading)}
                                        variant="contained"
                                        style={{fontWeight: 700 ,float:'right',width:'50%',borderRadius:0}}
                                    >
                                        {signUpReducer.isLoading?"Creating Account ...":"Create Account"}
                                    </OrangeFilledButton>
                                </div><br/>
                            </form>
                            {/*</Box>
                        </Container>
                    </Box>
                </Grid>
                <Grid item xs={4}/>
            </Grid> */}
            <Box  mx={4} px={2}>
                <Box >
                    <Typography variant={'h1'} style={{fontWeight:700}}>Create Account </Typography>
                </Box>
                <Grid container spacing={6}>
                    <Grid item xs={6} >
                        <Box py={1} className={'flex'} >
                            <Typography variant='h4' style={{color:theme.palette.secondary.light,marginTop:'5px'}}>Please fill up the basic details below :</Typography>
                        </Box>
                        <Box  minHeight={"50%"} borderTop={border}>
                        <Container maxWidth="md" component="main" style={{marginLeft:'0px',paddingLeft:"0px"}}>
                            <Box py={2}>
                                {!reset?
                                <ResponseMessage signUpReducer={signUpReducer} response={signUpReducer.data}/>
                                :null}
                            </Box>
                            <form id="signupForm" className={classes.form} autoComplete={'off'} onSubmit={e =>
                                handleSubmit(e, {
                                    first_name,
                                    last_name,
                                    email,
                                    password,
                                    confirm_password,
                                    company_name,
                                    terms_accepted
                                })
                            }>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Typography component={'span'} color={'primary'} >First
                                            Name *</Typography>
                                        <Box mb={2} mt={1}>
                                            <CustomTextField
                                                helperText={firstNameError}
                                                onFocus={() => setFirstNameError('')}
                                                onBlur={() => {
                                                    checkNames(first_name, setFirstNameError,"First")
                                                }}
                                                placeholder={'Type your First Name'}
                                                type="text"
                                                name="first_name"
                                                value={first_name}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                    checkNames(e.target.value, setFirstNameError,"First")
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                <Grid item xs={12} md={6} >
                                <Typography component={'span'} color={'primary'}>Last
                                    Name *</Typography>
                                <Box mb={2} mt={1}>
                                    <CustomTextField
                                        helperText={lastNameError}
                                        onFocus={() => setLastNameError('')}
                                        onBlur={() => {
                                            checkNames(last_name, setLastNameError,"Last")
                                        }}
                                        placeholder={'Type your Last Name'}
                                        type="text"
                                        name="last_name"
                                        value={last_name}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            checkNames(e.target.value, setLastNameError,"Last")
                                        }}
                                    />
                                </Box>
                                </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid xs={12} md={12}>
                                    <Typography component={'span'} color={'primary'} >Company
                                        Name *</Typography>
                                    <Box mb={2} mt={1}>
                                        <CustomTextField
                                            helperText={companyNameError}
                                            onFocus={() => setCompanyNameError('')}
                                            onBlur={() => {
                                                checkCompanyNames(company_name, setCompanyNameError,"Company")
                                            }}
                                            placeholder={'Type your Company Name'}
                                            type="text"
                                            name="company_name"
                                            value={company_name}
                                            onChange={(e) => {
                                                setCompanyName(e.target.value);
                                                checkCompanyNames(e.target.value, setCompanyNameError,"Company")
                                            }}
                                        />
                                    </Box>
                                    </Grid>
                                </Grid>
                                <Typography component={'span'} color={'primary'}>Email
                                    Address *</Typography>
                                <Box mb={2} mt={1}>
                                    <CustomTextField
                                        helperText={emailError}
                                        onFocus={() => setEmailError('')}
                                        onBlur={() => {
                                            verifyEmail(email)
                                        }}
                                        placeholder={'Type your Email'}
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            verifyEmail(e.target.value)
                                        }}
                                    />
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                <Typography component={'span'} color={'primary'}>Create a
                                    Password *</Typography>&ensp;<HtmlTooltip
                                title={<Typography variant={"body2"} color={'primary'}>
                                    <ul style={{padding: 8, margin: 8, marginTop: 0, marginBottom: 0}}>
                                        <li>Your password can't be too similar to your other personal information.</li>
                                        <li>Your password must contain at least 8 characters.</li>
                                        <li>Your password can't be a commonly used password.</li>
                                        <li>Your password can't be entirely numeric.</li>
                                    </ul>
                                </Typography>} placement={"right"}
                                interactive="true">{Icons.infoCircleIconOutlineMargin}</HtmlTooltip>
                                <Box mb={2} mt={1}>
                                    <CustomTextField
                                        helperText={passwordError}
                                        onFocus={() => setPasswordError('')}
                                        onBlur={() => {
                                            checkPassword(password)
                                        }}
                                        placeholder={'Type a Strong Password'}
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            checkPassword(e.target.value)
                                        }}
                                    />
                                </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <Typography component={'span'} color={'primary'}>Confirm
                                    Password *</Typography>
                                <Box mb={2} mt={1}>
                                    <CustomTextField
                                        helperText={confirmPasswordError}
                                        onFocus={() => setConfirmPasswordError('')}
                                        onBlur={() => {
                                            if (password !== confirm_password) {
                                                setConfirmPasswordError("Password doesn't match")
                                            } else {
                                                setConfirmPasswordError("")
                                            }
                                        }}
                                        placeholder={'Confirm Password'}
                                        type="password"
                                        name="confirm_password"
                                        value={confirm_password}
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                            if (password !== e.target.value) {
                                                setConfirmPasswordError("Password doesn't match")
                                            } else {
                                                setConfirmPasswordError("")
                                            }
                                        }}
                                    />
                                </Box>
                                </Grid>
                                </Grid>
                                <Box mb={2} style={{display:'flex',float:'right',alignItems:'center'}}>
                                            <FormControlLabel
                                                control={<CustomCheckbox checked={terms_accepted}
                                                                         onChange={(e, newChecked) => {
                                                                             set_terms_accepted(newChecked)
                                                                         }} name="terms_accepted"/>}
                                                component={'span'} style={{fontSize: 20, marginRight: 5}}
                                            />
                                        <Typography>I agree to
                                            LXRInsights'&nbsp;
                                            <Link target="_blank" rel="noopener noreferrer" to={'/terms'}
                                                  onClick={() => window.scrollTo(0, 0)}>T&C</Link> and<Typography component={'span'}> </Typography>
                                            <Link target="_blank" rel="noopener noreferrer" to={'/privacy-policy'}
                                                  onClick={() => window.scrollTo(0, 0)}>Privacy
                                                Policy</Link>.
                                        </Typography>
                                </Box>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <TransparentButton
                                        onClick={handleReset}
                                        style={{width:'60%',borderRadius:0,textTransform:'capitalize',fontWeight:400,color:'#858585'}}>
                                            <Replay style={{fontSize:"1.125rem"}}/>&nbsp;Reset
                                        </TransparentButton>
                                    </Grid>

                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={6}>
                                        <OrangeFilledButton
                                            type="submit"
                                            disabled={(!validateForm())||(signUpReducer.isLoading)}
                                            variant="contained"
                                            style={{float:'right',width:'75%',borderRadius:0,fontWeight:400}}
                                        >
                                            <PersonAddAlt style={{fontSize:"1.125rem"}}/>
                                            &nbsp;{signUpReducer.isLoading?"Creating Account ...":"Create Account"}
                                        </OrangeFilledButton>
                                    </Grid>
                                </Grid><br/>
                            </form>
                        </Container>
                        </Box>
                    </Grid>
                    <Grid item xs={6}  >
                        <Box borderBottom={border} py={2.5} className={'flex'} style={{paddingBottom:'22px'}}>
                        </Box>
                        <Box borderLeft={border} style={{marginLeft:'-25px',textAlign:'center'}}>
                            <Box style={{display:'flex',justifyContent: "center",position: "relative"}} mt={3}>
                                {Icons.ixFrame}
                                <Typography variant="h4" style={{color:"#8F8F8F"}}>
                                Welcome to LXRInsights. <br/>
                                Where&nbsp;
                                <span style={{color:theme.palette.secondary.light,fontWeight:700}}>
                                    Your Data meets Insights.
                                </span>
                                </Typography>
                            </Box>
                            <Box>
                                {Icons.signupIllustration}
                            </Box>
                            <Box>
                            <Typography variant="h4" style={{color:"#8F8F8F"}}>
                                Get Started with Tracking <br/>
                                Choose your  <span style={{color:theme.palette.secondary.light,fontWeight:700}}>Tracking Mode</span> for Detailed Insights
                            </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

const mapStateToProps = (state) => ({
    signUpReducer: state.signUpReducer
});
const mapDispatchToProps = (dispatch) => ({
    handle_sign_up: (e, data) => dispatch(handle_sign_up(e, data)),
    clearSignupStatus:()=>dispatch(clearSignupStatus())
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);