import { Box, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import * as _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { border } from "../../assets/jss/commonStyles";
import { GAEventTracker } from "../../index";
import { CustomSwitch } from "../groupPage/monthTrendHeader";
function OverviewHeader(props) {
  const { data, classes, handleCompare, compare } = props;
  useEffect(() => {}, [props]);
  const allCustomers = _.find(data, { segment: "all" })
    ? _.find(data, { segment: "all" })
    : _.find(data, { segment: "c" });
  return data ? (
    <Box
      className={clsx("flex", classes.headerStyles, classes.header)}
      borderBottom={border}
    >
      <Grid container alignItems={"center"}>
        <Grid item xs={4}>
          <Typography
            variant={"h4"}
            color={"primary"}
            style={{ fontWeight: 700 }}
          >
            Overview
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={7}>
          <Grid container spacing={1} style={{ justifyContent: "right" }}>
            <Grid
              item
              xs={6}
              style={{ alignItems: "end", textAlign: "right" }}
              id={"tour5(1)thStep"}
            >
              {compare ? (
                <Box
                  style={{ display: "flex", float: "right" }}
                  borderRight={border}
                >
                  <Typography
                    component={"span"}
                    color={"primary"}
                    variant={"h5"}
                    style={{ marginTop: "5px" }}
                  >
                    Switch to Default
                  </Typography>
                  <CustomSwitch
                    checked={compare}
                    onClick={() => {
                      handleCompare(false);
                      GAEventTracker("Overview", "Default View");
                    }}
                  />
                </Box>
              ) : (
                <Box
                  style={{ display: "flex", float: "right" }}
                  borderRight={border}
                >
                  <Typography
                    component={"span"}
                    color={"primary"}
                    variant={"h5"}
                    style={{ marginTop: "5px" }}
                  >
                    Switch to Compare
                  </Typography>
                  <CustomSwitch
                    checked={compare}
                    onClick={() => {
                      handleCompare(true);
                      GAEventTracker("Overview", "Compare View");
                    }}
                  />
                </Box>
              )}
            </Grid>
            <Grid
              id={"tour4thStep"}
              item
              xs={5}
              style={{
                textAlign: "end",
                float: "right",
                marginTop: "5px",
                justifyContent: "right",
                minWidth: "300px",
              }}
            >
              <Typography
                component={"span"}
                variant={"h5"}
                color={"primary"}
                style={{ opacity: 0.5 }}
              >
                Showing Results for :&nbsp;
              </Typography>
              <Typography
                component={"span"}
                variant={"h5"}
                color={"primary"}
                style={{ fontWeight: 700 }}
              >
                &nbsp;
                {moment(allCustomers?.data_start).format("MMM YYYY")}
                &nbsp;to&nbsp;
                {moment(allCustomers?.data_end).format("MMM YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : null;
}

export default OverviewHeader;
