import React from 'react';
import { Checkbox } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material';



export const CustomCheckbox = withStyles({
  root: {
    color: '#747474', // Color of the checkbox when unchecked
    '&$checked': {
      color: '#747474', // Color of the checkbox when checked
      backgroundColor: '#fffff',
    },
    '&$checked:hover': {
      backgroundColor: 'transparent', // Remove the hover effect on the background when checked
    },
  },
  checked: {
    '&$checked': {
      color: '#747474', // Color of the tick when checked
    },
  },
})((props) => (
  <Checkbox
    icon={<CheckBoxOutlineBlankOutlined />} // Icon for the unchecked state
    checkedIcon={<CheckBoxOutlined />} // Icon for the checked state
    {...props}
  />
));


export const CustomCheckbox1 = withStyles({
  root: {
    color: '#747474', // Color of the checkbox when unchecked
    '&$checked': {
      color: '#747474', // Color of the checkbox when checked
      backgroundColor: '#fffff',
    },
    '&$checked:hover': {
      backgroundColor: 'transparent', // Remove the hover effect on the background when checked
    },
    width:'1.5rem',
    height:'1.5rem',
  },
  checked: {
    '&$checked': {
      color: '#747474', // Color of the tick when checked
    },
  },
})((props) => (
  <Checkbox
    icon={<CheckBoxOutlineBlankOutlined style={{fontSize:'1rem'}}/>} // Icon for the unchecked state
    checkedIcon={<CheckBoxOutlined />} // Icon for the checked state
    {...props}
  />
));

export const CustomCheckboxOrangeOutline = withStyles({
  root: {
    color: "#F58120", // Color of the checkbox when unchecked
    '&$checked': {
      color: '#F58120', // Color of the checkbox when checked
      backgroundColor: '#fffff',
    },
    '&$checked:hover': {
      backgroundColor: 'transparent', // Remove the hover effect on the background when checked
    },
    width:'1.5rem',
    height:'1.5rem',
  },
  checked: {
    '&$checked': {
      color: '#F58120', // Color of the tick when checked
    },
  },
})((props) => (
  <Checkbox
    icon={<CheckBoxOutlineBlankOutlined />} // Icon for the unchecked state
    checkedIcon={<CheckBoxOutlined />} // Icon for the checked state
    {...props}
  />
));

export const CustomCheckboxOrangeOutline1 = withStyles({
  root: {
    color: "#747474", // Color of the checkbox when unchecked
    '&$checked': {
      color: '#F58120', // Color of the checkbox when checked
      backgroundColor: '#fffff',
    },
    '&$checked:hover': {
      backgroundColor: 'transparent', // Remove the hover effect on the background when checked
    },
    width:'1.5rem',
    height:'1.5rem',
    '& svg': {
      fontSize: '20px', // Adjust font size here
    },
  },
  checked: {
    '&$checked': {
      color: '#F58120', // Color of the tick when checked
    },
  },
})((props) => (
  <Checkbox
    icon={<CheckBoxOutlineBlankOutlined />} // Icon for the unchecked state
    checkedIcon={<CheckBoxOutlined />} // Icon for the checked state
    {...props}
  />
));



