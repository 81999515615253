import React, {useEffect} from "react";
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import HeaderStyles from "../../assets/jss/headers/headerStyles";
import Icons from "../icons";
import {TransparentButton} from "../buttons";
import {handle_login} from "../../actions/actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {GAEventTracker} from "../../index";
import {redirectToPage} from "../user";

function LoggedOutHeader(props) {
    const classes = HeaderStyles();
    const {fromShopify} = props;

    const handleSignup = () => {
        window.location.hash = '/signup'
    }



    return (
        <>
            <AppBar className={classes.appBar} position={"fixed"}>
                <Box alignItems={'center'}>
                    <Toolbar disableGutters className={classes.containerSec} id="headSec">
                        <Box className={clsx(classes.flexGrow1, 'flex')} style={{alignItems: 'center'}}>
                            {/*<Box py={'auto'} px={2} borderRight={"1px solid #1e334e"}>*/}
                            <Link to={"/"} className={clsx("flex", "text-decoration-none")}>
                                <Box className={classes.logo}>
                                    {/*{Icons.insightsWhiteLogo}*/}
                                    {Icons.LXRInsights_Logo}
                                </Box>
                            </Link>
                            {/*<a target={"_blank"} rel="noopener noreferrer" href={"https://www.netelixir.com/"}
                               className={clsx("flex", "text-decoration-none")}>
                                <Box py={'auto'} px={2} onClick={() => {
                                    GAEventTracker('Header', 'NetElixir Logo')
                                }}>
                                    {Icons.netelixirLogo}
                                </Box>
                            </a>*/}
                        </Box>
                        {fromShopify?null:
                        <Box textAlign={"right"} className={classes.loginButton}>
                            <TransparentButton onClick={() => {
                                redirectToPage("/login")
                                // handleLoginOpen();
                                GAEventTracker("Header", 'Login')
                            }}>
                                <Typography className={classes.loginButtonTypo} >
                                    Log In
                                </Typography>
                            </TransparentButton>
                        </Box>}
                        {fromShopify?null:
                        <Box textAlign={"right"} className={classes.loginButton} >
                            <TransparentButton onClick={() => {
                                handleSignup();
                                GAEventTracker("Header", 'Signup')
                            }} style={{borderRadius: '30px',background: '#F58120',color: '#fff'}}>
                                <Typography className={classes.loginButtonTypo}>
                                    Sign Up
                                </Typography>
                            </TransparentButton>
                        </Box>}

                    </Toolbar>
                </Box>
            </AppBar>
            {/*<LoginForm handle_login={handle_login} popLoginOpen={popLoginOpen} onClose={handleLoginClose}/>*/}
        </>
    )
}

const mapStateToProps = (state) => ({
    loginAuthentication: state.loginAuthentication,
    application_states:state.application_states
});
const mapDispatchToProps = (dispatch) => ({
    handle_login: (e, data) => dispatch(handle_login(e, data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LoggedOutHeader);
