import makeStyles from '@mui/styles/makeStyles';
import XIIcon from "../images/LoginPage/dotted-bg.svg";
const App_URL = process.env.REACT_APP_AppUrl;

const LoginFormStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        minHeight:'10px'
    },
    content: {
        flexGrow: 1,
        width: "100%",
        background: `url(${App_URL}${XIIcon}) repeat`,
        backgroundColor: "#FBFCFD",
        // backgroundSize: "10%",
        // marginTop:'100px'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerClass: {
        color: theme.palette.secondary.dark,
        fontSize: 24
    },
    avatar: {
        margin: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto",
        padding:"32px",
        backgroundColor: theme.palette.primary.main,
        fontSize: 40
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        fontSize: "1.25rem",
        margin: theme.spacing(3, 0, 2),
        backgroundColor: theme.palette.primary.main,
        borderRadius: 0
    },
    demoHeader:{
        textTransform: 'uppercase',
        color: theme.palette.primary.main
    },
    successFont:{
        color:theme.palette.success.main,
        textAlign:'center'
    },
    errorFont:{
        color:theme.palette.error.main,
        textAlign:'center'
    },
}))

export default LoginFormStyles;